import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const role = window.localStorage.getItem('role');

const AccountType = () => {
  let rolRes = 'error';
  if (role === 'supervisor') {
    rolRes = 'supervisors';
  } else if (role === 'company') {
    rolRes = 'companies';
  }
  return rolRes;
};
console.log('AccountType',AccountType())
const initialState = {
  isLoading: false,
  error: false,
  countries: [],
  city: [],
  lotto: [],
  agents: [],
  supervisors: [],
  surcussales: [],
  tirages: [],
  fiches: [],
  fichesupps: [],
  fichegagnants: [],
  borletteConf: [],
  typeGame: [],
  typeGameAgent: [],
  typeBoule: [],
  typeBouleAgent: [],
  primeConfs: null,
  tirageprimeConfs: [],
  agentprimeConfs: [],
  app: null,
  appTirageLotgagnant: [],
  appTirage: null,
  appFiche: null,
  appLotto: null,
  appGain: null,
  appGpc: null,
  infotirages: [],
  boules: [],
  statistiques: [],
  tracabiliteAgent: [],
  tracabiliteSupervisor: [],
  tracabiliteCompany: [],
  info_tirages: [],
  rapports: [],
  rapportagents: [],
  agentRapport: [],
  supRapport: [],
  agentsupRapport: null,
  lotgagnants: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // GET CITY
    getCitySuccess(state, action) {
      state.isLoading = false;
      state.city = action.payload;
    },

    // GET LOTTO
    getLottoSuccess(state, action) {
      state.isLoading = false;
      state.lotto = action.payload;
    },

    // GET AGENTS
    getAgentsSuccess(state, action) {
      state.isLoading = false;
      state.agents = action.payload;
    },

    // GET SUPERVISORS
    getSupervisorsSuccess(state, action) {
      state.isLoading = false;
      state.supervisors = action.payload;
    },

    // GET SURCUSSALES
    getSurcussaleSuccess(state, action) {
      state.isLoading = false;
      state.surcussales = action.payload;
    },

    // GET TIRAGES
    getTirageSuccess(state, action) {
      state.isLoading = false;
      state.tirages = action.payload;
    },

    // GET FICHES
    getFicheSuccess(state, action) {
      state.isLoading = false;
      state.fiches = action.payload;
    },

    // GET FICHES GAGNANTS
    getFicheGagnantSuccess(state, action) {
      state.isLoading = false;
      state.fichegagnants = action.payload;
    },

    // GET FICHES SUPP
    getFicheSuppSuccess(state, action) {
      state.isLoading = false;
      state.fichesupps = action.payload;
    },

    // GET BOULES
    getBouleSuccess(state, action) {
      state.isLoading = false;
      state.boules = action.payload;
    },

    // GET PRIMECONF
    getPrimeConfSuccess(state, action) {
      state.isLoading = false;
      state.primeConfs = action.payload;
    },

    // GET TIRAGECONF
    getTirageConfSuccess(state, action) {
      state.isLoading = false;
      state.tirageprimeConfs = action.payload;
    },

    // GET AGENTCONF
    getAgentConfSuccess(state, action) {
      state.isLoading = false;
      state.agentprimeConfs = action.payload;
    },

    // GET APP
    getAppSuccess(state, action) {
      state.isLoading = false;
      state.app = action.payload;
    },

    // GET RAPPORT BY AGENT
    getAgentRapportSuccess(state, action) {
      state.isLoading = false;
      state.agentRapport = action.payload;
    },

    // GET RAPPORT BY SUPERVISOR
    getSupRapportSuccess(state, action) {
      state.isLoading = false;
      state.supRapport = action.payload;
    },

    // GET RAPPORT AG + SUPERVISOR
    getASRapportSuccess(state, action) {
      state.isLoading = false;
      state.agentsupRapport = action.payload;
    },

    // GET APP LOT Gagnant
    getAppTirageLotGagnantSuccess(state, action) {
      state.isLoading = false;
      state.appTirageLotgagnant = action.payload;
    },

    // GET APP TIRAGE
    getAppTirageSuccess(state, action) {
      state.isLoading = false;
      state.appTirage = action.payload;
    },

    // GET APP FICHE
    getAppFicheSuccess(state, action) {
      state.isLoading = false;
      state.appFiche = action.payload;
    },

    // GET APP LOTTO
    getAppLottoSuccess(state, action) {
      state.isLoading = false;
      state.appLotto = action.payload;
    },

    // GET APP GAIN
    getAppGainSuccess(state, action) {
      state.isLoading = false;
      state.appGain = action.payload;
    },

    // GET APP GPC
    getAppGpcSuccess(state, action) {
      state.isLoading = false;
      state.appGpc = action.payload;
    },

    // GET TYPEGAME
    getTypeGameSuccess(state, action) {
      state.isLoading = false;
      state.typeGame = action.payload;
    },

    // GET TYPEGAMEAGENT
    getTypeGameAgentSuccess(state, action) {
      state.isLoading = false;
      state.typeGameAgent = action.payload;
    },

    // GET TYPEBOULE
    getTypeBouleSuccess(state, action) {
      state.isLoading = false;
      state.typeBoule = action.payload;
    },

    // GET TYPEBOULEAGENT
    getTypeBouleAgentSuccess(state, action) {
      state.isLoading = false;
      state.typeBouleAgent = action.payload;
    },

    // GET INFOTIRAGE
    getInfoTirageSuccess(state, action) {
      state.isLoading = false;
      state.infotirages = action.payload;
    },

    // GET STATISTIQUES
    getStatistiqueSuccess(state, action) {
      state.isLoading = false;
      state.statistiques = action.payload;
    },

    // GET TRACABILITE AGENTS
    getTracabiliteAgentSuccess(state, action) {
      state.isLoading = false;
      state.tracabiliteAgent = action.payload;
    },

    // GET TRACABILITE SUPERVISORS
    getTracabiliteSupervisorSuccess(state, action) {
      state.isLoading = false;
      state.tracabiliteSupervisor = action.payload;
    },

    // GET TRACABILITE COMPANIES
    getTracabiliteCompanySuccess(state, action) {
      state.isLoading = false;
      state.tracabiliteCompany = action.payload;
    },

    // GET RAPPORTS
    getRapportSuccess(state, action) {
      state.isLoading = false;
      state.rapports = action.payload;
    },

    // GET RAPPORTS
    getRapportAgentSuccess(state, action) {
      state.isLoading = false;
      state.rapportagents = action.payload;
    },

    // GET LOT GAGNANTS
    getLotGagnantSuccess(state, action) {
      state.isLoading = false;
      state.lotgagnants = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/generals/countries');
      dispatch(slice.actions.getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

export function getCity() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/generals/cities');
      dispatch(slice.actions.getCitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

export function getLottos() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/list-lotto`);
      dispatch(slice.actions.getLottoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAgents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/list-agent`);
      dispatch(slice.actions.getAgentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getSupervisors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/list-supervisor`);
      dispatch(slice.actions.getSupervisorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getSurcussales() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/list-surcussale`);
      dispatch(slice.actions.getSurcussaleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTirages(value, value1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/list-tirage?start_date=${value}&end_date=${value1}`);
      dispatch(slice.actions.getTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getFiches(value, value1, value2, value3, value4) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/fiche?start_date=${value}&end_date=${value1}&tirage_id=${value2}&surcussale_id=${value3}&agent_id=${value4}`
      );
      dispatch(slice.actions.getFicheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getFicheGagnants(value, value1, value2, value3, value4) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/fiche-gagnants?start_date=${value}&end_date=${value1}&tirage_id=${value2}&surcussale_id=${value3}&agent_id=${value4}`
      );
      dispatch(slice.actions.getFicheGagnantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getFicheSupps(value, value1, value2, value3, value4) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/fiche-supp?start_date=${value}&end_date=${value1}&tirage_id=${value2}&surcussale_id=${value3}&agent_id=${value4}`
      );
      dispatch(slice.actions.getFicheSuppSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTypeGames() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/type-games`);
      dispatch(slice.actions.getTypeGameSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTypeGameAgents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/type-games-agent`);
      dispatch(slice.actions.getTypeGameAgentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTypeBoules() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/type-boules`);
      dispatch(slice.actions.getTypeBouleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTypeBouleAgents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/type-boules-agent`);
      dispatch(slice.actions.getTypeBouleAgentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getBoules() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/boules-block`);
      dispatch(slice.actions.getBouleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getInfoTirages() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/info-tirage`);
      dispatch(slice.actions.getInfoTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getPrimeConfs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/prime`);
      dispatch(slice.actions.getPrimeConfSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTirageConfs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/prime-tirage`);
      dispatch(slice.actions.getTirageConfSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAgentConfs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/prime-agent`);
      dispatch(slice.actions.getAgentConfSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getApp() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/app`);
      dispatch(slice.actions.getAppSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAgentRapports() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/rapport-journalier`);
      dispatch(slice.actions.getAgentRapportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getSupRapports() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/rapport-journalier-sup`);
      dispatch(slice.actions.getSupRapportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getASRapports() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/rapport-journalier-ag-sup`);
      dispatch(slice.actions.getASRapportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppTirageLotgagnant() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appTirage-lotgagnant`);
      dispatch(slice.actions.getAppTirageLotGagnantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppTirage() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appTirage`);
      dispatch(slice.actions.getAppTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppFiche() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appFiche`);
      dispatch(slice.actions.getAppFicheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppLotto() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appLotto`);
      dispatch(slice.actions.getAppLottoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppGain() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appGain`);
      dispatch(slice.actions.getAppGainSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppGpc() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/appGpc`);
      dispatch(slice.actions.getAppGpcSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getRapports(value, value1, value2, value3, value4, value5) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/companies/rapports?start_date=${value}&end_date=${value1}&tirage_id=${value2}&surcussale_id=${value3}&agent_id=${value4}&filter_choice=${value5}`
      );
      dispatch(slice.actions.getRapportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getRapportAgents(value, value1, value2, value3, value4, value5) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/rapports-agent?start_date=${value}&end_date=${value1}&tirage_id=${value2}&surcussale_id=${value3}&agent_id=${value4}&filter_choice=${value5}`
      );
      dispatch(slice.actions.getRapportAgentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getStatistiques(value, value1, value21, value2, value3, value4) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/statistiques?start_date=${value}&end_date=${value1}&tirage_id=${value21}&lotto_id=${value2}&surcussale_id=${value3}&agent_id=${value4}`
      );
      dispatch(slice.actions.getStatistiqueSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTracabiliteAgents(value, value1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/tracabilites-agents?start_date=${value}&end_date=${value1}`
      );
      dispatch(slice.actions.getTracabiliteAgentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTracabiliteSupervisors(value, value1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/tracabilites-supervisors?start_date=${value}&end_date=${value1}`
      );
      dispatch(slice.actions.getTracabiliteSupervisorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTracabiliteCompanies(value, value1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/${AccountType()}/tracabilites-companies?start_date=${value}&end_date=${value1}`
      );
      dispatch(slice.actions.getTracabiliteCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}

export function getLotGagnants() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/${AccountType()}/lotgagnant`);
      dispatch(slice.actions.getLotGagnantSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        window.location.reload();
      }
    }
  };
}
