// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const displayName = user?.companyname ? user?.companyname : user?.first_name;

  return (
    <MAvatar
      src={user?.user_photo}
      alt={displayName}
      color={user?.user_photo ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
