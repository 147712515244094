// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  fiche_view: (refCode) => `/fiche-view/${refCode}`,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette'),
    blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule'),
    add_blocage_boule: path(ROOTS_DASHBOARD, '/borlette/blocage-boule/new'),
    lotto: path(ROOTS_DASHBOARD, '/borlette/lottos'),
    statistique: path(ROOTS_DASHBOARD, '/borlette/statistiques'),
    list: path(ROOTS_DASHBOARD, '/borlette/list-fiches'),
    list_fgagnant: path(ROOTS_DASHBOARD, '/borlette/list-fiches-gagnants'),
    list_fsupp: path(ROOTS_DASHBOARD, '/borlette/list-fiches-supprimer')
  },
  configuration_borlette: {
    root: path(ROOTS_DASHBOARD, '/borlette-configuration'),
    type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game'),
    add_type_game: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game/new'),
    type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent'),
    add_type_game_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-game-per-agent/new'),
    type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule'),
    add_type_boule: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule/new'),
    type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent'),
    add_type_boule_agent: path(ROOTS_DASHBOARD, '/borlette-configuration/limite-boule-per-agent/new')
  },
  tirage: {
    root: path(ROOTS_DASHBOARD, '/tirage'),
    newTirage: path(ROOTS_DASHBOARD, '/tirage/new'),
    list: path(ROOTS_DASHBOARD, '/tirage/list'),
    configuration: path(ROOTS_DASHBOARD, '/tirage/configuration'),
    configurationAgent: path(ROOTS_DASHBOARD, '/tirage/configuration/ConfAgent'),
    configuration_type_agent: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/conf/${type}`),
    configuration_type: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}`),
    edit_configuration: (type, configurationId) =>
      path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/${configurationId}/edit`),
    add_configuration: (type) => path(ROOTS_DASHBOARD, `/tirage/configuration/${type}/new`),
    info_tirage: path(ROOTS_DASHBOARD, '/tirage/info'),
    add_info_tirage: path(ROOTS_DASHBOARD, '/tirage/infotirage-new')
  },
  rapport: {
    root: path(ROOTS_DASHBOARD, '/rapports/root'),
    journalier: path(ROOTS_DASHBOARD, '/rapports/journalier'),
    agent: path(ROOTS_DASHBOARD, '/rapports/agent')
  },
  agent: {
    root: path(ROOTS_DASHBOARD, '/agent'),
    list: path(ROOTS_DASHBOARD, '/agent/list'),
    newAgent: path(ROOTS_DASHBOARD, '/agent/new')
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, '/supervisor'),
    list: path(ROOTS_DASHBOARD, '/supervisor/list'),
    newSupervisor: path(ROOTS_DASHBOARD, '/supervisor/new')
  },
  surcussale: {
    root: path(ROOTS_DASHBOARD, '/surcussale'),
    list: path(ROOTS_DASHBOARD, '/surcussale/list'),
    newSurcussale: path(ROOTS_DASHBOARD, '/surcussale/new')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    tracabilite_agent: path(ROOTS_DASHBOARD, '/user/tracabilite-agent'),
    tracabilite_me: path(ROOTS_DASHBOARD, '/user/tracabilite-company/me'),
    tracabilite_supervisor: path(ROOTS_DASHBOARD, '/user/tracabilite-supervisor')
  }
};
